<template>
  <b-container class="password-change">
    <b-row class="justify-content-center"> 
      <b-col sm="6" style="margin-top: 15%">
          <b-form>
              <b-form-group align="center">
                <img src="@/assets/logo-we-prevent-md.png" height="200px"/>
              </b-form-group>
              <!-- <p>UUID: {{ $route.params.uuid }}</p> -->
              <br>
              <p>Setup your password</p>
              <v-text-field
                  class="warning-custom"
                  v-model="password"
                  label="Password"
                  name="password"
                  prepend-icon="mdi-lock"
                  type="password"
                  :rules="passwordRules"
                  @input="validateForm"
                  
                />
                <v-text-field
                  class="warning-custom"
                  v-model="confirmPassword"
                  label="Confirm Password"
                  name="confirmPassword"
                  prepend-icon="mdi-lock"
                  type="password"
                  :rules="confirmPasswordRules"
                  @input="validateForm"
                />
               <br> 
              <b-form-group align="right">
                <b-button block @click="handlePasswordChange" type="button" variant="success" :disabled="!form_valid">Update</b-button>
              </b-form-group>
          </b-form>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
    name: 'Login',
    data() {
        return {
          form_valid: false,
          password: '',
          confirmPassword: '',
          passwordRules: [
            (value) => !!value || 'Please type password.',
            (value) => (value && value.length >= 8) || 'minimum 8 characters',
            (value) => /(?=.*[@#$%^&+*!=])/.test(value) || 'must contain at least one special character (@#$%^&+*!=)',
            (value) => /(?=.*[0-9])/.test(value) || 'must contain at least one number',
            (value) => /(?=.*[a-z])/.test(value) || 'must contain at least one lowercase letter',
            (value) => /(?=.*[A-Z])/.test(value) || 'must contain at least one uppercase letter',
          ],
          confirmPasswordRules: [
            (value) => !!value || 'type confirm password',
            (value) =>
              value === this.password || 'The password confirmation does not match.',
          ],
        }
    },
    computed: {
      loggedIn() {
        return this.$store.state.auth.status.loggedIn;
      }
    },
    methods: {
        validateForm() {
          this.form_valid=true;
          if ( !(/(?=.*[@#$%^&+*!=])/.test(this.password)) ){this.form_valid=false}
          else if ( !(/(?=.*[0-9])/.test(this.password)) ){this.form_valid=false}
          else if ( !(/(?=.*[a-z])/.test(this.password)) ){this.form_valid=false}
          else if ( !(/(?=.*[A-Z])/.test(this.password)) ){this.form_valid=false}
          else if (this.password.length < 8){this.form_valid=false}
          else if (this.password !== this.confirmPassword){this.form_valid=false}
          else {this.form_valid=true}
        },
        handlePasswordChange() {
          if (this.form_valid) {
            this.$store.dispatch('auth/password_change_uuid', {
              uuid: this.$route.params.uuid,
              password: this.password,
            }).then(
              (success) => {
                if(success){
                  alert("Success: Password changed.");
                }else{
                  alert("Error: Failed to change password. Please contact technical assistance: y.bingham@imperial.ac.uk");
                }
                this.$router.push('/login');
              }
            );
          }
        },
    },
    beforeMount(){
      this.$store.dispatch('auth/password_change_uuid_verify', this.$route.params.uuid).then(
        (valid) => {
          if(!valid){
            // this.$router.push('/login');
          }
        }
      );
    }
}
</script>

<style scoped>

.error--text{
  color: rgb(255, 0, 0) !important;
  caret-color: rgb(255, 0, 0) !important;
}
  .warning-custom >>> .message-transition-enter-to {
    color: red
  }
</style>