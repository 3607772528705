<template>
    <div class="questionaire-viewer container" style="margin-top: 5%; margin-bottom: 5%">
        <h3>We-Prevent Study</h3>
        <p>Date of Symptom: <b style="color:blue">{{date_symptom}}</b></p>
        <hr style="margin-top: 2.5%; margin-bottom: 2.5%" />
        <QuestionaireDaily :questionnaire_data="questionnaire_data" v-if="$route.params.id == 1" />
        <QuestionaireWeekly :questionnaire_data="questionnaire_data" v-if="$route.params.id == 2" />
        <QuestionaireMonthly :questionnaire_data="questionnaire_data" v-if="$route.params.id == 3" />
        <hr style="margin-top: 2.5%; margin-bottom: 2.5%" />
        <b-row >
            <b-col></b-col>
            <b-col cols="4">
                <b-button block variant="secondary" type="button" @click="onReturn">Return</b-button>
            </b-col>
            <b-col></b-col>
        </b-row>
        <br style="margin-top: 5%; margin-bottom: 2.5%" />
    </div>
</template>

<script>
import QuestionaireDaily from "@/components/QuestionaireDaily";
import QuestionaireWeekly from "@/components/QuestionaireWeekly";
import QuestionaireMonthly from "@/components/QuestionaireMonthly";
import ApiService from "@/services/api.service";

export default {
    name: 'QuestionaireViewer',
    components: {
        QuestionaireDaily,
        QuestionaireWeekly,
        QuestionaireMonthly
    },
    data() {
        return {
            questionnaire_data: null,
            date_symptom: null,
        }
    },
    methods: {
        refresh(){
            ApiService.get("/p/questionnaire-answer/" + this.$route.params.uuid)
            .then(({ data }) => {
                this.questionnaire_data = data.data.answer_json;
                this.date_symptom = new Date(data.data.symptoms_date).toDateString();
            });
        },
        onReturn() {
            this.$router.push('/dashboard');
        },
    },    
    watch: {
        $route() {
        this.refresh();
        }
    },
    mounted() {
        this.refresh();
    },
}
</script>