import axios from 'axios';
import JwtService from "@/services/jwt.service";
import ApiService from "@/services/api.service";

// const API_URL = 'http://localhost:3000/auth/';
const API_URL = 'https://api.hygieai.com/auth/';

class AuthService {
  login(user) {
    return axios
      .post(API_URL + 'login', {
        username_email: user.email_username,
        password: user.password
      })
      .then(response => {
        if (response.data.accessToken) {
          localStorage.setItem('user', JSON.stringify(response.data));
          JwtService.saveToken(response.data.accessToken);
          ApiService.setHeader();
        }
        return response.data;
      });
  }

  forgot(email_phone) {
    ApiService.post("/auth/forgot-access", {email_phone: email_phone})
    .then(({ data }) => {
      return data;
    }).catch(({ err })=> {
        return err;
    });
  }

  password_change_uuid_verify(password_change_uuid) {
    return axios
      .post(API_URL + "password-change-verify", {
        password_change_uuid: password_change_uuid
      })
      .then(response => {
        return response.data;
      });
  }

  password_change_uuid(data) {
    return axios
      .post(API_URL + "password-change-uuid", {
        password_change_uuid: data.uuid,
        password: data.password
      })
      .then(response => {
        return response.data;
      });
  }

  logout() {
    localStorage.removeItem('user');
    JwtService.destroyToken();
  }

  verify_auth() {
    if(localStorage.getItem('user')){
      var user = JSON.parse(localStorage.getItem('user'));
      if(user.userTypeId){
        if(user.userTypeId != 1){
          this.logout();
          return;
        }
      }
      if(user.accessToken){
        JwtService.saveToken(user.accessToken);
        ApiService.setHeader();
      }
      ApiService.get("/auth/verify")
      .then(({ data }) => {
        if(data.accessToken){
          localStorage.setItem('user', JSON.stringify(data));
          JwtService.saveToken(data.accessToken);
          ApiService.setHeader();
        }else{
          this.logout();
        }
      }).catch(({ err })=> {
          console.log(err);
          this.logout();
      });
    }else{
      this.logout();
    }
  }

}

export default new AuthService();
