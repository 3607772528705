<template>
    <div class="questionaire-daily">
        <b-container v-for="q in questionnaire_data" :key="q.id" class="question justify-content-center">
            <b-row>
                <span v-html="q.question"></span>
            </b-row>
            <b-row v-if="!isMobile()">
                <b-form-radio-group
                    v-model="q.answer"
                    :options="q.options"
                    :aria-describedby="ariaDescribedby"
                ></b-form-radio-group>
            </b-row>
            <b-row v-else>
                <b-form-radio-group
                    v-model="q.answer"
                    :options="q.options"
                    :aria-describedby="ariaDescribedby"
                    stacked
                ></b-form-radio-group>
            </b-row>
        </b-container>
    </div>
</template>

<script>
  export default {
    name: "QuestionaireDaily",
    props: ["questionnaire_data"],
    methods: {
        isMobile() {
            if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        }
    }
  }
</script>

<style scoped>
    .container.question{
    margin-top: 5%;
    }
</style>