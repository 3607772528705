<template>
  <div id="app">
    <router-view />
    <AppFooter />
  </div>
</template>

<script>
import AppFooter from "@/components/Footer";

export default {
    name: 'App',
    components: {
      AppFooter
    }
}
</script>

<style>
    html {
      position: relative;
      min-height: 100%;
    }

    body {
      /* padding: 50px; */
      font: 14px "Lucida Grande", Helvetica, Arial, sans-serif;
      margin-bottom: 60px; /* Margin bottom by footer height */
    }

</style>

