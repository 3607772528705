import Vue from 'vue'
import Router from 'vue-router'
import DashboardComponent from './views/Dashboard.vue'
import LoginComponent from "./views/Login.vue"
import PasswordChangeComponent from "./views/PasswordChange.vue"
import QuestionaireAnswererComponent from "./views/QuestionaireAnswerer.vue"
import QuestionaireEditorComponent from "./views/QuestionaireEditor.vue"
import QuestionaireViewerComponent from "./views/QuestionaireViewer.vue"

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      redirect: '/dashboard'
    },
    {
      path: '/password_change/:uuid',
      name: 'password_change',
      component: PasswordChangeComponent
    },
    {
      path: '/login',
      name: 'login',
      component: LoginComponent
    },
    {
      path: '/dashboard',
      component: DashboardComponent,
      meta: { requiresAuth: true }
    },
    {
      path: '/answer/questionaire/:id/:uuid',
      component: QuestionaireAnswererComponent,
      meta: { requiresAuth: true },
      props: true
    },
    {
      path: '/view/questionaire/:id/:uuid',
      component: QuestionaireViewerComponent,
      meta: { requiresAuth: true },
      props: true
    },
    {
      path: '/edit/questionaire/:id/:uuid',
      component: QuestionaireEditorComponent,
      meta: { requiresAuth: true },
      props: true
    }
  ]
});