<template>
    <footer class="footer">
        <div class="container">
            <span class="text-muted">© <a style="color:grey" href="https://rtanaka.bg-research.cc.ic.ac.uk/" target="_blank">Tanaka Group, Imperial College London</a></span>
        </div>
    </footer>
</template>

<script>
export default {
  name: "AppFooter"
};
</script>

<style>
    .footer {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 2%; /* Set the fixed height of the footer here */
        /* line-height: 1.5%; Vertically center the text there */
        background-color: #f5f5f5;
        }
</style>