<template>
  <b-container class="login">
    <b-row class="justify-content-center"> 
      <b-col sm="6" style="margin-top: 15%">
          <b-form>
              <b-form-group align="center">
                <img src="@/assets/logo-we-prevent-md.png" height="200px"/>
              </b-form-group>
              <br>
              <b-form-group align="left" v-if="login_failed">
                <p style="color: red">Login failed. Wrong credentials.</p>
              </b-form-group>
              <b-form-group label="Email/Phone" label-for="email_username">
                <b-form-input type="text" v-model="user.email_username" @keydown="validateLoginForm" @update="validateLoginForm" placeholder="e.g. james@example.com" />
              </b-form-group>
              <b-form-group label="Password" label-for="password">
                <b-form-input type="password" v-model="user.password" @keydown="validateLoginForm" @update="validateLoginForm"/>
              </b-form-group>
              <b-form-group align="right">
                <b-button block @click="handleLogin" type="button" variant="primary" :disabled="!login_form_valid">Login</b-button>
              </b-form-group>
              <b-form-group align="right">
                <a href="#" type="button" v-b-modal.modal-patient-forgot-password>Reset Access</a>
              </b-form-group>
          </b-form>
      </b-col>
    </b-row>
    <b-modal id="modal-patient-forgot-password" size="lg" title="Reset Access" @ok="onConfirmForgotPassword">
      <b-form>
        <b-form-group
          id="pw-input-group"
          label="Email/Phone:"
          description="Enter your email address or phone number to recieve access information again.">
          <b-form-input
            v-model="pw_forgot_email_phone"
            type="text"
            placeholder="(e.g. james@example.com, or +447901001234)"
            required
          ></b-form-input>
        </b-form-group>
      </b-form>
    </b-modal>
  </b-container>
</template>

<script>
import User from '../models/user'

export default {
    name: 'Login',
    data() {
        return {
          user: new User('', ''),
          login_form_valid: false,
          login_failed: false,
          pw_forgot_email_phone: null
        }
    },
    computed: {
      loggedIn() {
        return this.$store.state.auth.status.loggedIn;
      }
    },
    methods: {
        validateLoginForm() {
          this.login_failed=false
          if( this.user.email_username == '' || this.user.email_username == null){
            this.login_form_valid=false
          }else if (this.user.password == '' || this.user.password == null){
            this.login_form_valid=false
          }else{
            this.login_form_valid=true
          }
        },
        handleLogin() {
          if (this.user.email_username && this.user.password) {
            this.$store.dispatch('auth/login', this.user).then(
              () => {
                this.$router.push('/dashboard');
              },
              error => {
                this.login_failed = true;
                console.warn(error);
              }
            );
          }
        },
        onConfirmForgotPassword(evt){
          evt.preventDefault();
          this.$store.dispatch('auth/forgot', this.pw_forgot_email_phone).then(
              () => {
                this.$bvModal.hide("modal-patient-forgot-password");
                alert("Your email/phone is found in the system. You should expect to recieve the access information again in a few minutes.");
              },
              error => {
                console.warn(error);
                alert("The email/phone you entered is not found in the system. Please contact technical assistance: y.bingham@imperial.ac.uk");
              }
            );
        },
    },
    beforeMount(){
      if(this.$store.state.auth.user){
        this.$router.push('/dashboard');
      }
      if (this.user.email_username.length > 0 && this.user.password.length > 0){
        this.login_form_valid=true
      }
    }
}
</script>