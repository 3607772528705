import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vuex from 'vuex';
import vuetify from '@/plugins/vuetify' // path to vuetify export
import ApiService from "@/services/api.service";
import AuthService from "@/services/auth.service";
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// API service init
ApiService.init();

// Make BootstrapVue available throughout the project
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(Vuex);

router.beforeEach((to, from, next) => {
  // Ensure we checked auth before each page load.
  Promise.all([AuthService.verify_auth()]).then(next);

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);

  if (to.matched.some(record => record.meta.requiresAuth) && !localStorage.getItem('user')) {
      // You can use store variable here to access globalError or commit mutation 
      next({ name: 'login' })
  } else {
      next();
  }
});

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
