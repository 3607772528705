<template>
  <b-container class="dashboard">
    <b-row class="justify-content-left" style="margin-top: 15%">
      <b-col>
          <h1>Welcome <b>{{ currentUser.firstname}}</b>,</h1>
      </b-col>
        <b-col cols="3" v-if="!isMobile()">
          <b-button variant="dark" type="button" block @click="onLogOut"><b-icon-arrow-up-right/> Logout</b-button>
        </b-col>
        <b-col cols="4" v-else>
          <b-button variant="dark" type="button" block @click="onLogOut">Logout</b-button>
        </b-col>
    </b-row>
  <b-row class="justify-content-left" style="margin-top: 2%">
      <b-col>
          <h5>Thank you for participating in the We-Prevent study.
              If you need any technical assistance, please contact <a href="mailto:y.bingham@imperial.ac.uk">y.bingham@imperial.ac.uk</a>.
          </h5>
      </b-col>
  </b-row>
  <b-row class="justify-content-center" style="margin-top: 5%">
      <b-col>
          <h3>My Records</h3>
      </b-col>
      <!-- <b-col cols="2" align="right">
        <b-button variant="info" type="button" block v-b-modal.new_submission_popup><b-icon-clipboard-plus /> New</b-button>
      </b-col> -->
  </b-row>
  <b-row class="justify-content-center" style="margin-top: 20px">
      <b-col>
          <b-table id="my-records-table" show-empty sticky-header hover :items="questionnaire_records" :fields="record_table_fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :per-page="perPage" :current-page="currentPage">

            <template #cell(submission_type)="row">
              <p>{{questionaire_types.find(q_type => q_type.value === row.item.questionnaireId).text}}</p>
            </template>

            <template #cell(symptoms_date)="row">
              <p>{{ [row.item.symptoms_date.getDate(),  row.item.symptoms_date.getMonth()+1, row.item.symptoms_date.getFullYear()].join('/') }}</p>
            </template>

            <!-- <template #cell(status)="row">
              <p>{{ statusCheck(row) }}</p>
            </template> -->

            <template #cell(expiry_time)="row">
              <p>{{ expiryTime(row) }}</p>
            </template>

            <template #cell(actions)="row">
              <b-button v-if="statusCheck(row) == 'Answered'" variant="light" size="sm" class="mr-2" @click="onViewClicked(row)">View</b-button>
              <b-button v-if="statusCheck(row) == 'Answered'" variant="primary" size="sm" class="mr-2" @click="onEditClicked(row)">Edit</b-button>
              <b-button v-if="statusCheck(row) == 'Empty'" variant="success" size="sm" class="mr-2" @click="onAnswerClicked(row)">Answer Now</b-button>
            </template>


          </b-table>
          <b-pagination
            :total-rows="totalRows"
            v-model="currentPage"
            :per-page="perPage"
            align="fill"
            class="my-0"
            aria-controls="my-records-table"
            ></b-pagination>
      </b-col>
  </b-row>
  <!-- <b-modal id="new_submission_popup" size="md" title="Select Submission Type" @ok="onNewSubmit" @hidden="onResetSubmit" @show="onResetSubmit" ok-only ok-title="Start">
      <label for="new-submission-typepicker">Submission type</label>
      <b-form-select id="new-submission-typepicker" v-model="questionaire_newsubmission.type" :options="questionaire_types" class="mb-2"></b-form-select>
      <br>
      <label for="new-submission-datepicker">Submission date</label>
      <b-form-datepicker id="new-submission-datepicker" block v-model="questionaire_newsubmission.date" class="mb-2"></b-form-datepicker>
    </b-modal> -->
  </b-container>
</template>

<script>
import ApiService from "@/services/api.service";

export default {
  name: 'Dashboard',
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    totalRows() {
      return this.questionnaire_records.length
    }
  },
  data() {
    return{
      perPage: 10,
      currentPage: 1,
      sortBy: 'symptoms_date',
      sortDesc: true,
      record_table_fields: [
        // { key: "id", label: "Id"},
        { key: "symptoms_date", label: "Date of Symptoms", sortable: true, sortDirection: 'desc'},
        { key: "submission_type", label: "Type"},
        // { key: "status", label: "Status"},
        { key: "expiry_time", label: "Time to expiry"},
        { key: "actions", label: "Action" },
      ],
      questionaire_types: [
        { value: 1, text: "Daily questions"},
        { value: 2, text: "Weekly questions"},
        { value: 3, text: "Questionnaire"}
      ],
      questionaire_newsubmission: {
        type: null,
        date: null,
      },
      hist_records: [
          { id: 1, type: 1, submission_date: '2021-12-06'},
          { id: 2, type: 1, submission_date: '2021-12-05'},
          { id: 3, type: 2, submission_date: '2021-12-02'},
          { id: 4, type: 3, submission_date: '2021-11-29'},
        ],
      questionnaire_records: [],
    };
  },
  methods: {
    refresh() {
      ApiService.get("/p/questionnaires").then(({ data }) => {
        this.questionnaire_records = data.data;
        this.questionnaire_records.forEach(record => {
          record.symptoms_date = new Date(record.symptoms_date);
        });
        this.questionnaire_records = this.filterQuestionnaireRecords(this.questionnaire_records);
      });
    },
    isMobile() {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    },
    filterQuestionnaireRecords(records){
      var filteredRecords = [];
      var now = (new Date()).getTime();
      for(let i = 0; i < records.length; i++) {
          let record = records[i];
          var expire_date = record.symptoms_date.getTime() + this.currentUser.meta_data.submit_edit_window_hours * 60 * 60 * 1000;
          if ((now > record.symptoms_date) && (now < expire_date)){
            filteredRecords.push(record);
          }
      }
      return filteredRecords;
    },
    onLogOut() {
      this.$store.dispatch('auth/logout');
      this.$router.push('/login');
    },
    onNewSubmit(){
      if(this.questionaire_newsubmission.type != null){
        this.$router.push('/submit/questionaire/'+this.questionaire_newsubmission.type);
      }
    },
    onResetSubmit(){
      this.questionaire_newsubmission.type = null;
    },
    onViewClicked(row){
      this.$router.push('/view/questionaire/'+row.item.questionnaireId+'/'+row.item.id);
    },
    onEditClicked(row){
      var today = new Date();
      var expire_date = row.item.symptoms_date.getTime() + this.currentUser.meta_data.submit_edit_window_hours * 60 * 60 * 1000;
      if ((today > row.item.symptoms_date) && (today < expire_date)){
        this.$router.push('/edit/questionaire/'+row.item.questionnaireId+'/'+row.item.id);
      }
      else{
        alert("Action prohibited due to time limit. Please contact technical assistance if changes required.")
      }
    },
    onAnswerClicked(row){
      var today = new Date();
      var expire_date = row.item.symptoms_date.getTime() + this.currentUser.meta_data.submit_edit_window_hours * 60 * 60 * 1000;
      if ((today > row.item.symptoms_date) && (today < expire_date)){
        this.$router.push('/answer/questionaire/'+row.item.questionnaireId+'/'+row.item.id);
      }
      else{
        alert("Action prohibited due to time limit. Please contact technical assistance if changes required.")
      }
    },
    statusCheck(row){
      if (row.item.answer_json == null) {
        return "Empty";
      } else {
        return "Answered";
      }
    },
    expiryTime(row){
      var now = new Date().getTime();
      var expire_datetime = row.item.symptoms_date.getTime() + this.currentUser.meta_data.submit_edit_window_hours * 60 * 60 * 1000;
      var timeleft = expire_datetime - now;
      var days = Math.floor(timeleft / (1000 * 60 * 60 * 24));
      var hours = Math.floor((timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      return days + " Day(s) " + hours + " hour(s)";
    }
  },
  beforeMount(){
    if(!this.$store.state.auth.user){
      this.$router.push('/login');
    }
 },
 watch: {
    $route() {
      this.refresh();
    }
  },
  mounted() {
    this.refresh();
  },
}
</script>
