<template>
    <div class="questionaire-weekly">
        <div v-for="q in questionnaire_data" :key="q.id">
            <b-container class="question justify-content-center">
                <b-row>
                    <span v-html="q.question"></span>
                </b-row>
                <b-row v-if="!isMobile()">
                    <b-form-radio-group
                        v-model="q.answer"
                        :options="q.options"
                        :aria-describedby="ariaDescribedby"
                    ></b-form-radio-group>
                </b-row>
                <b-row v-else>
                    <b-form-radio-group
                        v-model="q.answer"
                        :options="q.options"
                        :aria-describedby="ariaDescribedby"
                        stacked
                    ></b-form-radio-group>
                </b-row>
            </b-container>
            <b-container class="sub-question justify-content-center" v-if="q.answer == true && q.id <= 2">
                <b-row>
                    <b-col cols="1"/>
                    <b-col>
                        <p>On how many days?</p>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="1"/>
                    <b-col v-if="!isMobile()">
                        <b-form-radio-group
                            v-model="q.days"
                            :options="days_options"
                            :aria-describedby="ariaDescribedby"
                        ></b-form-radio-group>
                    </b-col>
                    <b-col v-else>
                        <b-form-radio-group
                            v-model="q.days"
                            :options="days_options"
                            :aria-describedby="ariaDescribedby"
                            stacked
                        ></b-form-radio-group>
                    </b-col>
                </b-row>
            </b-container>
        </div>
    </div>
</template>

<script>
  export default {
    name: "QuestionaireWeekly",
    props: ["questionnaire_data"],
    methods: {
        isMobile() {
            if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        }
    },
    data() {
      return {
        days_options: [
            {value: 1, text: '1 Days'},
            {value: 2, text: '2 Days'},
            {value: 3, text: '3 Days'},
            {value: 4, text: '4 Days'},
            {value: 5, text: '5 Days'},
            {value: 6, text: '6 Days'},
            {value: 7, text: '7 Days'}
        ]
      }
    }
  }
</script>

<style scoped>
    .container.question{
    margin-top: 5%;
    }

    .container.sub-question{
    margin-top: 2%;
    }
</style>