<template>
    <div class="questionaire-answerer container" style="margin-top: 5%; margin-bottom: 5%">
        <h3>We-Prevent Study</h3>
        <p>Date of Symptom: <b style="color:blue">{{date_symptom}}</b></p>
        <hr style="margin-top: 2.5%; margin-bottom: 2.5%" />
        <QuestionaireDaily :questionnaire_data="questionnaire_data" v-if="$route.params.id == 1" />
        <QuestionaireWeekly :questionnaire_data="questionnaire_data" v-if="$route.params.id == 2" />
        <QuestionaireMonthly :questionnaire_data="questionnaire_data" v-if="$route.params.id == 3" />
        <hr style="margin-top: 2.5%; margin-bottom: 2.5%" />
        <b-row>
            <b-col></b-col>
            <b-col cols="4">
                <b-button block variant="secondary" type="button" @click="onCancel">Cancel</b-button>
            </b-col>
            <b-col cols="4">
                <b-button block variant="primary" type="button" @click="onSubmit">Submit</b-button>
            </b-col>
            <b-col></b-col>
        </b-row>
        <br style="margin-top: 5%; margin-bottom: 2.5%" />
    </div>
</template>

<script>
import QuestionaireDaily from "@/components/QuestionaireDaily";
import QuestionaireWeekly from "@/components/QuestionaireWeekly";
import QuestionaireMonthly from "@/components/QuestionaireMonthly";
import ApiService from "@/services/api.service";

export default {
    name: 'QuestionaireAnswerer',
    components: {
        QuestionaireDaily,
        QuestionaireWeekly,
        QuestionaireMonthly
    },
    data() {
        return {
            questionnaire_data: null,
            date_symptom: null,
        }
    },
    methods: {
        refresh(){
            ApiService.get("/p/questionnaire/" + this.$route.params.id)
            .then(({ data }) => {
                this.questionnaire_data = data.data.questionnaire_json;
            });
            ApiService.get("/p/questionnaire-answer/" + this.$route.params.uuid)
            .then(({ data }) => {
                this.date_symptom = new Date(data.data.symptoms_date).toDateString();
            });
        },
        onCancel() {
            this.$router.push('/dashboard');
        },
        onSubmit() {
            
            var validSubmit = true;
            // this.questionnaire_data.forEach(q => {
            //     if(q.answer == null){
            //         validSubmit = false;
            //     }
            //     if('days' in q){
            //         if(q.answer== true && q.days == null){
            //             validSubmit = false;
            //         }
            //     }
            // });
            if(validSubmit == true){
                ApiService.put("/p/questionnaire/" + this.$route.params.uuid, {answer_json: this.questionnaire_data} )
                .then(() => {
                    // Success
                    alert("Thanks for answering! \r\nYour responses have been submitted.");
                    this.$router.push('/dashboard');
                })
                .catch(() => {
                    alert("Error: Saving the questionnaire.");
                });
            }else{
                alert("Please complete the questionnaire before submission.");
            }
            
        }
    },
    watch: {
        $route() {
        this.refresh();
        }
    },
    mounted() {
        this.refresh();
    },
}
</script>